<template>
  <div>
    <v-row no-gutters>
      <v-img
        height="709"
        position="relative"
        contain
        :src="require('@/assets/ielts/Rectangle1.svg')"
      >
        <v-row no-gutters justify="center" align="center" style="height: 100%">
          <v-col
            cols="5"
            class="d-flex"
            style="flex-direction: column; padding-left: 136px"
          >
            <h1 class="heading1">Mastermind IELTS Training Program</h1>
            <p class="pt-8">
              To put your skills to the test, we offer comprehensive mock tests
              that closely mirror the actual IELTS exam format. These tests
              encompass both written and speaking components, providing
              invaluable practice and allowing you to familiarize yourself with
              the exam conditions. Our detailed feedback and performance
              analysis will guide you in identifying areas for improvement,
              empowering you to refine your strategies and enhance your
              performance.
            </p>
            <div>
              <v-btn
                height="56"
                width="150"
                style="background: #0099dc; margin-top: 33px; padding: 16px"
                class="white--text"
              >
                Enroll now
                <v-icon size="20">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col style="margin-left: 180px">
<!--            <iframe-->
<!--              allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture"-->
<!--              allowfullscreen-->
<!--              height="477"-->
<!--              width="754"-->
<!--              style="border-radius: 20px; border: 0px solid"-->
<!--              src="https://www.ieltsacademy.org/wp/5-ways-score-high-ielts-exam/"-->
<!--            ></iframe>-->
          </v-col>
        </v-row>
      </v-img>
    </v-row>
    <v-row no-gutters justify="space-between">
      <v-col
        cols="9"
        class="d-flex justify-center"
        style="flex-direction: column"
      >
        <v-row
          no-gutters
          justify="center"
          align="center"
          style="margin-top: 60px"
        >
          <v-col
            class="d-flex"
            style="flex-direction: column; padding-left: 132px"
          >
            <h3
              class="xl:text-2xl lg:text-2xl md:text-2xl sm:text-2xl pl-2 pb-6"
              style="color: #2b4570"
            >
              Course highlights
            </h3>
            <v-row no-gutters class="d-flex justify-space-around pr-16">
              <v-card
                v-for="(adds, i) in advertisedContents"
                :key="i"
                flat
                height="198"
                width="198"
                class="rounded-xl pa-4"
                :color="adds.color"
              >
                <v-row class="ma-0">
                  <img :src="adds.icon" />
                </v-row>
                <v-row class="ma-0">
                  <p
                    style="
                      color: #2b4570;
                      font-family: Roboto;
                      font-size: 20px;
                      font-weight: 700;
                      padding-top: 8px;
                      padding-bottom: 5px;
                    "
                  >
                    {{ adds.count }}
                  </p>
                </v-row>
                <v-row class="ma-0">
                  <p
                    style="
                      color: #2b4570;
                      font-family: Roboto;
                      font-size: 20px;
                      font-weight: 700;
                    "
                  >
                    {{ adds.text }}
                  </p>
                </v-row>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          style="margin-top: 110px; margin-bottom: 50px"
          no-gutters
          justify="start"
          align="start"
        >
          <v-col cols="8" style="padding-left: 132px">
            <v-card style="color: #000" flat>
              <h3
                class="xl:text-2xl lg:text-2xl md:text-2xl sm:text-2xl pl-2 pb-6"
                style="color: #2b4570"
              >
                Course Table Contents
              </h3>
              <v-select
                v-if="screenWidth < 600"
                v-model="selectedSection"
                :items="
                  sections.map((section, i) => {
                    return {
                      text: `${section} (${section.contents.length})`,
                      value: i
                    };
                  })
                "
                dense
                outlined
              >
              </v-select>
              <v-slide-group
                v-else
                v-model="selectedSection"
                mandatory
                show-arrows
                class="pl-2"
              >
                <v-slide-item
                  v-for="(section, index) in sections"
                  :key="index"
                  v-slot="{ active, toggle }"
                >
                  <v-btn
                    depressed
                    class="mr-3 pa-2 d-flex align-center xl:text-sm lg:text-sm md:text-sm sm:text-xs"
                    :class="classes.slideItem.outer[active]"
                    :input-value="active"
                    @click="toggle"
                  >
                    {{ section.title }}
                    <div
                      class="ml-2 rounded"
                      :class="classes.slideItem.inner[active]"
                    >
                      {{ section.contents.length }}
                    </div>
                  </v-btn>
                </v-slide-item>
              </v-slide-group>
              <v-expansion-panels v-model="expandedModule" flat class="pl-2">
                <v-expansion-panel
                  v-for="(module_id, index) in selected"
                  :key="index"
                  active-class="panel-active"
                >
                  <v-expansion-panel-header
                    class="pl-0 pb-0"
                    hide-actions
                    disable-icon-rotate
                  >
                    <v-card flat color="#F4F4F4" class="panel-card">
                      <div
                        class="py-1 d-flex align-center"
                        :class="classes.header"
                      >
                        <div class="d-flex flex-grow-1 clickable">
                          <b
                            >{{ index + 1 }}. &nbsp;
                            {{ moduleName(module_id) }}</b
                          >
                        </div>
                        <v-btn icon>
                          <v-icon class="panel-arrow" size="25"
                            >{{
                              index != expandedModule ? "mdi-plus" : "mdi-close"
                            }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-card>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <public-module-contents
                      style="color: #f4f4f4"
                      :contents="getModuleContents(module_id)"
                      :module_id="module_id"
                    ></public-module-contents>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-card
          flat
          height="919"
          width="429"
          color="#FAFAFA"
          style="border-radius: 0"
        ></v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PublicModuleContents from "./ModuleContents.vue";
import { ResponsivenessUtils } from "/global/utils/mixins";

export default {
  name: "CourseDetails",
  components: { PublicModuleContents },
  mixins: [ResponsivenessUtils],
  data() {
    return {
      selectedSection: undefined,
      expandedModule: undefined,
    };
  },
  watch: {
    selectedSection(newVal) {
      if(newVal) {
        this.expandedModule = undefined
      }
    }
  },
  computed: {
    advertisedContents() {
      return [
        {
          text: "Interactive Lectures",
          count: "30+",
          icon: require("@/assets/ielts/course-details/material-symbols.svg"),
          color: "#c0ecff"
        },
        {
          text: "Question Specific Practice Tests",
          count: "50+",
          icon: require("@/assets/ielts/course-details/Group.svg"),
          color: "#e8f1ff"
        },
        {
          text: "Full Length Mock Tests",
          count: "20+",
          icon: require("@/assets/ielts/course-details/healthicons.svg"),
          color: "#edf4ff"
        },
        {
          text: "Dynamic Lecture Slides",
          count: "",
          icon: require("@/assets/ielts/course-details/Vector.svg"),
          color: "#f2f7ff"
        },
        {
          text: "Module Based Vocabulary Boost",
          count: "",
          icon: require("@/assets/ielts/course-details/Boost.svg"),
          color: "#fafcff"
        }
      ];
    },
    modules() {
      return this.$store.state.publicCourseDetails.modules;
    },
    sections() {
      return this.$store.state.publicCourseDetails.sections;
    },
    allContents() {
      return this.$store.state.publicCourseDetails.object_details;
    },
    allExams() {
      return this.$store.state.publicCourseDetails.exams;
    },
    selected() {
      if (this.selectedSection === undefined) return [];
      return this.sections[this.selectedSection].contents;
    },
    classes() {
      return {
        slideItem: {
          outer: {
            true: "primary white--text",
            false: "bg-white  black--text"
          },
          inner: {
            true: "white primary--text",
            false: "primary white--text"
          }
        },
        header: `px-${this.screenWidth < 600 ? 2 : 4}`
      };
    }
  },
  created() {
    this.getPublicCourseDetails();
  },
  methods: {
    async getPublicCourseDetails() {
      await this.$store.dispatch("fetchPublicCourseDetails");
    },
    expandedModuleIndex(index) {
      this.expandedModule = index;
    },
    moduleName(moduleId) {
      return this.modules[moduleId].title;
    },
    getModuleContents(moduleId) {
      let contents = this.modules[moduleId].contents;
      let moduleContents = [];
      contents.forEach(content => {
        if (content.id in this.allContents) {
          moduleContents.push(this.allContents[content.id]);
        }
        if (content.id in this.allExams) {
          moduleContents.push(this.allExams[content.id]);
        }
      });
      return moduleContents;
    },
    processAboutVid() {
      let url =
        "https://youtu.be/ccsz9FRy-nk?list=PLC3y8-rFHvwgeQIfSDtEGVvvSEPDkL_1f";
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      let code = match && match[2].length === 11 ? match[2] : null;
      if (!code) return this.course.about_video;
      else {
        return `https://www.youtube.com/embed/${code}`;
      }
    }
  }
};
</script>

<style>
.heading1 {
  color: #2b4570;
  font-family: Roboto;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
</style>
